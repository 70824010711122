<div class="container marginMenu">
  <p class="fs-5">
    <br /><br /><fa-icon [icon]="faQuoteLeft"></fa-icon>
    <span [innerHTML]="'Infos.Text' | translate"></span>
    <fa-icon [icon]="faQuoteRight"></fa-icon>
  </p>
  <br />
  <p class="text-end text-secondary fs-5">{{ "Infos.Sign" | translate }}</p>
  <br />
</div>

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { FormsModule } from '@angular/forms';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgxMasonryModule } from 'ngx-masonry';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import {
  NgcCookieConsentModule,
  NgcCookieConsentConfig,
} from 'ngx-cookieconsent';

import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import {
  AngularFireAnalyticsModule,
  ScreenTrackingService,
  UserTrackingService,
} from '@angular/fire/analytics';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { ContactsComponent } from './components/contacts/contacts.component';
import { FourOhFourComponent } from './components/four-oh-four/four-oh-four.component';
import { HomeComponent } from './components/home/home.component';
import { InfosComponent } from './components/infos/infos.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { ProjectsComponent } from './components/projects/projects.component';
import { NgxParallaxScrollModule } from 'ngx-parallax-scroll';
import { FooterComponent } from './components/footer/footer.component';
import { ProjectService } from './services/project.service';
import { CreateProjectComponent } from './components/admin/create-project/create-project.component';
import { EditProjectComponent } from './components/admin/edit-project/edit-project.component';
import { ViewAllProjectComponent } from './components/admin/view-all-project/view-all-project.component';
import { ViewProjectComponent } from './components/view-project/view-project.component';

import { OrderModule, OrderPipe } from 'ngx-order-pipe';

import { AuthService } from './services/auth.service';
import { LoginComponent } from './components/login/login.component';
import { MentionLegalComponent } from './components/mention-legal/mention-legal.component';
import { DashboardAdminComponent } from './components/admin/dashboard-admin/dashboard-admin.component';
import { ProjectParameterComponent } from './components/admin/project-parameter/project-parameter.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: environment.cookieDomain,
  },
  position: 'bottom',
  theme: 'classic',
  palette: {
    popup: {
      background: '#000000',
      text: '#ffffff',
      link: '#ffffff',
    },
    button: {
      background: '#54544f',
      text: '#ffffff',
      border: 'transparent',
    },
  },
  type: 'info',
  content: {
    message:
      'This website uses cookies to ensure you get the best experience on our website.',
    dismiss: 'Got it!',
    deny: 'Refuse cookies',
    link: 'Learn more',
    href: 'https://cookiesandyou.com',
    policy: 'Cookie Policy',
  },
};

@NgModule({
  declarations: [
    AppComponent,
    ContactsComponent,
    FourOhFourComponent,
    HomeComponent,
    InfosComponent,
    NavBarComponent,
    ProjectsComponent,

    FooterComponent,
    CreateProjectComponent,
    EditProjectComponent,
    ViewAllProjectComponent,
    ViewProjectComponent,
    LoginComponent,
    MentionLegalComponent,
    DashboardAdminComponent,
    ProjectParameterComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgxMasonryModule,
    FontAwesomeModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireAuthModule,
    AngularFireAnalyticsModule,
    FormsModule,
    NgxParallaxScrollModule,
    OrderModule,
    HttpClientModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    ProjectService,
    AuthService,
    ScreenTrackingService,
    UserTrackingService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

<div class="container marginMenu">
  <br />
  <div
    class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 row-cols-xxl-6 g-2 justify-content-md-center"
  >
    <div class="col">
      <div class="card h-100 bg-dark lego">
        <!-- <img
          src="../../../assets/img/contact-lego-jeff.png"
          class="card-img-top"
          alt="..."
        /> -->
        <div class="card-body">
          <h6 class="card-title">Jean-Francois BONNEMENT</h6>
          <p class="card-text text-secondary">
            {{ "Contact.Partner" | translate }} <br />
            {{ "Contact.LightingDesigner" | translate }} <br />
            {{ "Contact.DirectorOfPhotography" | translate }}
          </p>
          <div class="d-grid gap-2">
            <a href="mailto:jeff@4eleven.fr" class="text-secondary">
              jeff@4eleven.fr
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="col">
      <div class="card h-100 bg-dark lego">
        <img
          src="../../../assets/img/contact-lego-alex.png"
          class="card-img-top"
          alt="..."
        />
        <div class="card-body">
          <h6 class="card-title">Alexandre BUCHER</h6>
          <p class="card-text text-secondary">
            {{ "Contact.Partner" | translate }} <br />
            {{ "Contact.LightingDesigner" | translate }} <br />
            {{ "Contact.SetDesigner" | translate }}
          </p>
          <div class="d-grid gap-2">
            <a href="mailto:alex@4eleven.fr" class="text-secondary">
              alex@4eleven.fr
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="card h-100 bg-dark lego">
        <img
          src="../../../assets/img/contact-lego-thomas.png"
          class="card-img-top"
          alt="..."
        />
        <div class="card-body lego">
          <h6 class="card-title">Thomas DECHANDON</h6>
          <p class="card-text text-secondary">
            {{ "Contact.Partner" | translate }} <br />
            {{ "Contact.LightingDesigner" | translate }} <br />
            {{ "Contact.SetDesigner" | translate }}
          </p>
          <div class="d-grid gap-2">
            <a href="mailto:thomas@4eleven.fr" class="text-secondary">
              thomas@4eleven.fr
            </a>
          </div>
        </div>
      </div>
    </div>-->
    
    <div class="col">
      <div class="card h-100 bg-dark lego">
        <!-- <img
          src="../../../assets/img/contact-lego-nicolas.png"
          class="card-img-top"
          alt="..."
        /> -->
        <div class="card-body">
          <h6 class="card-title">Nicolas VALETTE</h6>
          <p class="card-text text-secondary">
            {{ "Contact.Partner" | translate }} <br />
            {{ "Contact.LightingDesigner" | translate }} <br />
            {{ "Contact.DirectorOfPhotography" | translate }}
          </p>
          <div class="d-grid gap-2">
            <a href="mailto:nicolas@4eleven.fr" class="text-secondary">
              nicolas@4eleven.fr
            </a>
          </div>
        </div>
      </div>
    </div>
	<div class="col">
		<div class="card h-100 bg-dark lego">
		  <!-- <img
			src="../../../assets/img/contact-lego-christophe.png"
			class="card-img-top"
			alt="..."
		  /> -->
		  <div class="card-body">
			<h6 class="card-title">Adrien MAURY</h6>
			<p class="card-text text-secondary">
			  <!-- {{ "Contact.Partner" | translate }} <br /> -->
			  <!-- {{ "Contact.LightingDesigner" | translate }} <br /> -->
			  {{ "Contact.Programmer" | translate }}
			</p>
			<div class="d-grid gap-2">
			  <a href="mailto:christophe@4eleven.fr" class="text-secondary">
				adrien@4eleven.fr
			  </a>
			</div>
		  </div>
		</div>
	  </div>
    <div class="col">
      <div class="card h-100 bg-dark lego">
        <!-- <img
          src="../../../assets/img/contact-lego-nathalie.png"
          class="card-img-top"
          alt="..."
        /> -->
        <div class="card-body">
          <h6 class="card-title">Nathalie ROIRAND</h6>
          <p class="card-text text-secondary">
            {{ "Contact.AdministrativeManager" | translate }}<br /><br /><br />
          </p>
          <div class="d-grid gap-2">
            <a href="mailto:nathalie@4eleven.fr" class="text-secondary">
              nathalie@4eleven.fr
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <br />
  <br />
</div>

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import {
  contributorType,
  contributorTypeId,
  ProjectService,
} from 'src/app/services/project.service';

@Component({
  selector: 'app-project-parameter',
  templateUrl: './project-parameter.component.html',
  styleUrls: ['./project-parameter.component.scss'],
})
export class ProjectParameterComponent implements OnInit {
  contributors!: contributorTypeId[];
  constructor(
    private projectService: ProjectService,
    private router: Router,
    private translate: TranslateService
  ) {
    projectService.contributorsType.subscribe((x) => (this.contributors = x));
  }

  ngOnInit(): void {}
  test() {
    this.translate.setTranslation(
      'en',
      {
        HELLO: 'hello you',
      },
      true
    );
    this.translate.setTranslation(
      'fr',
      {
        HELLO: 'salut toi',
      },
      true
    );
  }
  updateContributorType(id: string, data: contributorType) {
    this.projectService.updateContributorType(id, data);
  }
  onDeleteContributorType(id: string) {
    this.projectService.deleteContributorType(id);
  }
  onNewContributorId() {
    this.projectService.storeNewContributorType({ en: '', fr: '' });
  }
}

<div class="container-fluid marginMenu">
  <div
    class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 row-cols-xxl-6 g-1"
  >
    <div
      class="col"
      *ngFor="let project of projects | async | orderBy: 'yearEnd':true"
    >
      <div
        class="card bg-dark text-white h-100"
        (click)="editProject(project.id)"
      >
        <div class="card-header">
          <p class="card-title text-center">
            {{ project.clientName | uppercase }}
            <span class="text-info">{{ project.yearEnd }}</span>
          </p>
          <p class="card-text text-center text-secondary">
            {{ project.projectName | titlecase }}
          </p>
        </div>
        <video
          *ngIf="project.coverImage.type === 'video'"
          src="{{ project.coverImage.url }}"
          class="card-img"
        ></video>
        <img
          *ngIf="project.coverImage.type === 'image'"
          src="{{ project.coverImage.url }}"
          class="card-img"
        />
        <img
          *ngIf="project.coverImage.url === ''"
          src="../../../../assets/img/img-po.jpg"
          class="card-img"
          alt=""
        />

        <div class="card-footer">
          <small *ngIf="project.showOnSite" class="badge bg-success text-dark">
            ON AIR
          </small>
          <small *ngIf="!project.showOnSite" class="badge bg-danger text-dark">
            OFFLINE
          </small>
          <small> &nbsp;</small>
          <small *ngIf="project.showOnHome" class="badge bg-info text-dark">
            HOME PAGE
          </small>
        </div>
      </div>
    </div>
  </div>
</div>
<br />

<div class="container-fluid bg-dark marginMenu">
  <br />

  <div class="col-md-6">
    <label class="form-label">Liste des types de contributeurs :</label>
    <div *ngFor="let contributor of contributors">
      <div class="input-group">
        <input
          type="text"
          class="form-control bg-dark"
          placeholder="Anglais"
          [(ngModel)]="contributor.en"
          (change)="updateContributorType(contributor.id, contributor)"
        /><input
          type="text"
          class="form-control bg-dark"
          placeholder="francais"
          [(ngModel)]="contributor.fr"
          (change)="updateContributorType(contributor.id, contributor)"
        />

        <button
          class="btn btn-outline-danger"
          type="button"
          (click)="onDeleteContributorType(contributor.id)"
        >
          Delete
        </button>
      </div>
    </div>
    <button
      (click)="onNewContributorId()"
      type="button"
      class="btn btn-outline-secondary d-grid gap-2 col-12 mx-auto"
    >
      Nouveau type de contributeur
    </button>
  </div>
  <br />
</div>

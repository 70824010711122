<div class="container-fluid">
  <div class="row">
    <div class="col text-center">
      <p class="text-light">
        <a class="text-light text-center" routerLink="/mention-legal"
          >Mentions légales</a
        >
        - © 4eleven 2021
      </p>
    </div>
  </div>
</div>

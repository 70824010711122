<ngx-masonry class="marginMenu" [options]="masonryOptions">
  <div
    ngxMasonryItem
    class="masonry-item text-white"
    *ngFor="let project of projects | async | orderBy: 'yearEnd':true"
  >
    <video
      class="card-img"
      *ngIf="project.coverImage.type === 'video'"
      src="{{ project.coverImage.url }}"
      autoplay
      loop
      [muted]="'muted'"
    ></video>
    <img
      class="card-img"
      *ngIf="project.coverImage.type === 'image'"
      src="{{ project.coverImage.url }}"
    />

    <div class="card-img-overlay" (click)="navigateToProject(project.id)">
      <div class="row h-100">
        <div class="col-sm-12 my-auto">
          <h5 class="text-center fw-bold text-uppercase text-light fs-1">
            {{ project.clientName }}
          </h5>
          <p class="text-center fw-bold text-capitalize text-light fs-3">
            {{ project.projectName }}
          </p>
          <p class="text-center fw-bold text-capitalize text-light fs-6">
            {{ project.location }} / {{ project.yearStart }}
            <span *ngIf="project.yearStart != project.yearEnd">
              - {{ project.yearEnd }}</span
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</ngx-masonry>

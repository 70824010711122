import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  constructor(private translate: TranslateService) {
    this.navBar();
    this.Home();
    this.Projects();
    this.Info();
    this.Contact();
    this.Cookie();
  }

  navBar() {
    this.translate.setTranslation(
      'fr',
      {
        Menu: {
          Home: 'Accueil',
          Projects: 'Projets',
          ProjectsTags: {
            live: 'Concert',
            exhibition: 'exposition',
            event: 'événementiel',
            'broadcast event': 'événementiel diffusé',
            'comedy show': 'spectacle humoristique',
            architectural: 'architectural',
            'lighting design': 'conception lumière',
            scenography: 'scenographie',
          },
          Infos: 'A propos',
          Contacts: 'Contact',
        },
      },
      true
    );
    this.translate.setTranslation(
      'en',
      {
        Menu: {
          Home: 'Home',
          Projects: 'Projects',
          ProjectsTags: {
            live: 'live',
            exhibition: 'exhibition',
            event: 'event',
            'broadcast event': 'broadcast event',
            'comedy show': 'comedy show',
            architectural: 'architectural',
            'lighting design': 'lighting Design',
            scenography: 'scenography',
          },
          Infos: 'About',
          Contacts: 'Contact',
        },
      },
      true
    );
  }
  Home() {
    this.translate.setTranslation(
      'fr',
      {
        viewProject: 'voir le projet',
      },
      true
    );
    this.translate.setTranslation(
      'en',
      {
        viewProject: 'view project',
      },
      true
    );
  }
  Projects() {
    //dans view project component
  }
  Info() {
    this.translate.setTranslation(
      'fr',
      {
        Infos: {
          Text:
            " Créée en avril 2016, 4eleven est une société regroupant plusieurs concepteurs lumières et opérateurs. <br />Elle se définit comme une agence créative, proposant ses services dans le design, l'opérating console, mais aussi le conseil et l'accompagnement de tout projet scénographique, artistique ou architectural.<br />Les domaines d'interventions sont variés, allant de l'évènementiel au monde de l'exposition, les son et lumières, ou encore les spectacles en tournée et les festivals. <br />Toujours à l'écoute des nouvelles tendances technologiques, et soucieux de l'impact environnemental de notre activité, nous sommes à l'écoute de nos clients et des acteurs techniques de nos métiers, dans le but de répondre le plus fidèlement possible aux demandes, en mettant tout en oeuvre pour les satisfaire au mieux. <br />Un projet ? Un renseignement ? Besoin d'un conseil ? N'hésitez pas à nous contacter, nous serons heureux de pouvoir vous aider. ",
          Sign: "- L'équipe 4eleven",
        },
      },
      true
    );
    this.translate.setTranslation(
      'en',
      {
        Infos: {
          Text:
            ' Founded on april 2016, 4eleven is a company that regroups lighting designers, programmers and operators. <br />4Eleven defines itself as a creative agency, proposing design and operating services, as well as advice and technical support for scenographic, artistic or architectural project. <br />From special events exhibitions, sound and lights shows, touring shows and festivals our fields of intervention are many and varied. <br />Always in tune with the new technological trends, and respectful of the environmental impact of our activity, we listen carefully to our customers and the technical partners of our jobs, with the aim of answering as faithfully as possible your brief and introducing our creative ideas to amplify the impact of your project. <br />A project ? A question ? Need an advice ? Feel free to contact us, we will be happy to help you. ',
          Sign: '- The 4eleven team',
        },
      },
      true
    );
  }
  Contact() {
    this.translate.setTranslation(
      'fr',
      {
        Contact: {
          Partner: 'Associé',
          LightingDesigner: 'Designer lumière',
          DirectorOfPhotography: 'Directeur photo',
          SetDesigner: 'Scénographe',
          AdministrativeManager: 'Responsable administratif',
          Programmer: 'Opérateur lumière',

        },
      },
      true
    );
    this.translate.setTranslation(
      'en',
      {
        Contact: {
          Partner: 'Partner',
          LightingDesigner: 'Lighting designer',
          DirectorOfPhotography: 'Director of photography',
          SetDesigner: 'Set designer',
          AdministrativeManager: 'Administrative manager',
          Programmer: 'Ligting programmer',
        },
      },
      true
    );
  }
  Cookie() {
    this.translate.setTranslation(
      'fr',
      {
        cookie: {
          header: 'Les cookies sont utilisés sur ce site.',
          message:
            'Ce site Web utilise des cookies pour vous garantir la meilleure expérience sur notre site Web.',
          dismiss: 'Compris !',
          allow: 'Autoriser cookies',
          deny: 'Refuser',
          link: 'En savoir plus',
          policy: 'Règle des cookies',
        },
      },
      true
    );
    this.translate.setTranslation(
      'en',
      {
        cookie: {
          header: 'Cookies used on the website!',
          message:
            'This website uses cookies to ensure you get the best experience on our website.',
          dismiss: 'Got it!',
          allow: 'Allow cookies',
          deny: 'Decline',
          link: 'Learn more',
          policy: 'Cookie Policy',
        },
      },
      true
    );
  }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { NgxMasonryOptions, NgxMasonryComponent } from 'ngx-masonry';
import { Observable } from 'rxjs';
import { projectId, ProjectService } from 'src/app/services/project.service';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss'],
})
export class ProjectsComponent implements OnInit, OnDestroy {
  category: string = '';
  projects!: Observable<projectId[]>;
  navigationSubscription;

  constructor(
    private projectService: ProjectService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    // subscribe to the router events - storing the subscription so
    // we can unsubscribe later.
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        this.initialiseInvites();
      }
    });
  }

  initialiseInvites() {
    // Set default values and re-fetch any data you need.
    this.ngOnInit();
  }
  ngOnInit(): void {
    this.category = this.route.snapshot.params['category'];
    this.projects = this.projectService.getProjectsByCategory(this.category);
  }

  navigateToProject(id: string) {
    this.router.navigate(['projects', this.category, id]);
  }

  public masonryOptions: NgxMasonryOptions = {
    resize: true,
  };
  ngOnDestroy() {
    // avoid memory leaks here by cleaning up after ourselves. If we
    // don't then we will continue to run our initialiseInvites()
    // method on every navigationEnd event.
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }
}

<nav class="navbar fixed-top navbar-expand-lg navbar-dark bgNavBar">
  <div class="container-fluid">
    <a style="z-index: 10">
      <img
        src="../../../assets/img/Logo_simple_normal_HD.png"
        class="imgSize"
        routerLink="/home"
      />
    </a>
    <button
      class="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarNavAltMarkup"
      aria-controls="navbarNavAltMarkup"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
      <ul class="navbar-nav me-auto mb-2 mb-lg-auto mx-auto">
        <li class="nav-item">
          <a
            class="nav-link fs-5"
            (click)="ToggleNavBar()"
            routerLinkActive="active"
            aria-current="page"
            routerLink="/home"
            >{{ "Menu.Home" | translate }}</a
          >
        </li>
        <li class="nav-item dropdown">
          <a
            class="nav-link dropdown-toggle fs-5"
            id="navbarDropdown"
            (click)="ToggleNavBar()"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {{ "Menu.Projects" | translate }}
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
            <li *ngFor="let category of categoryList">
              <a
                *ngIf="category != '-'"
                class="dropdown-item"
                routerLinkActive="active"
                (click)="ToggleNavBar()"
                routerLink="/projects/{{ category }}"
                >{{
                  "Menu.ProjectsTags." + category | translate | titlecase
                }}</a
              >
              <hr *ngIf="category === '-'" class="dropdown-divider" />
            </li>
          </ul>
        </li>
        <li class="nav-item">
          <a
            class="nav-link fs-5"
            routerLinkActive="active"
            (click)="ToggleNavBar()"
            routerLink="/infos"
            >{{ "Menu.Infos" | translate }}</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link fs-5"
            routerLinkActive="active"
            (click)="ToggleNavBar()"
            routerLink="/contacts"
            >{{ "Menu.Contacts" | translate }}</a
          >
        </li>
        <li class="nav-item">
          <a
            *ngIf="auth.user | async"
            class="nav-link fs-5"
            routerLinkActive="active"
            (click)="ToggleNavBar()"
            routerLink="/dashboard-admin"
            >Admin</a
          >
        </li>

        <li class="nav-item">
          <a
            *ngIf="auth.user | async"
            class="nav-link fs-5"
            (click)="logOut()"
            (click)="ToggleNavBar()"
            >Log Out</a
          >
        </li>
      </ul>
      <div class="d-flex">
        <a class="navbar-brand" (click)="onChangeLang(); ToggleNavBar()">
          <img
            src="{{ langFlag }}"
            style="margin-bottom: 2px; cursor: pointer"
            width="18"
            height="18"
          />
        </a>
        <a
          class="navbar-brand"
          href="https://www.facebook.com/4eleven.fr/"
          target="_blank"
          (click)="ToggleNavBar()"
        >
          <fa-icon [icon]="facebook"></fa-icon>
        </a>
        <a
          class="navbar-brand"
          href="https://www.instagram.com/4eleven.fr/"
          target="_blank"
          (click)="ToggleNavBar()"
        >
          <fa-icon [icon]="insta"></fa-icon>
        </a>
        <a
          class="navbar-brand"
          href="https://www.linkedin.com/company/4eleven"
          target="_blank"
          (click)="ToggleNavBar()"
        >
          <fa-icon [icon]="linkedIn"></fa-icon>
        </a>
      </div>
    </div>
  </div>
</nav>

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { project, ProjectService } from 'src/app/services/project.service';

@Component({
  selector: 'app-create-project',
  templateUrl: './create-project.component.html',
  styleUrls: ['./create-project.component.scss'],
})
export class CreateProjectComponent implements OnInit {
  project: project = this.projectService.initProject;

  constructor(private projectService: ProjectService, private router: Router) {}

  ngOnInit(): void {}

  onCreateNewProject() {
    this.projectService.storeNewProjects(this.project);
    this.router.navigate(['view-all-project']);
  }
}

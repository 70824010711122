import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mention-legal',
  templateUrl: './mention-legal.component.html',
  styleUrls: ['./mention-legal.component.scss']
})
export class MentionLegalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import firebase from 'firebase/app';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(public auth: AngularFireAuth, private router: Router) {}
  login(email: string, psw: string) {
    this.auth
      .signInWithEmailAndPassword(email, psw)
      .finally(() => this.router.navigate(['home']));
  }
  logout() {
    this.auth.signOut();
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { ProjectsComponent } from './components/projects/projects.component';
import { InfosComponent } from './components/infos/infos.component';
import { ContactsComponent } from './components/contacts/contacts.component';
import { FourOhFourComponent } from './components/four-oh-four/four-oh-four.component';
import { CreateProjectComponent } from './components/admin/create-project/create-project.component';
import { ViewAllProjectComponent } from './components/admin/view-all-project/view-all-project.component';
import { EditProjectComponent } from './components/admin/edit-project/edit-project.component';
import { ViewProjectComponent } from './components/view-project/view-project.component';

import { AngularFireAuthGuard } from '@angular/fire/auth-guard';
import { LoginComponent } from './components/login/login.component';
import { MentionLegalComponent } from './components/mention-legal/mention-legal.component';
import { DashboardAdminComponent } from './components/admin/dashboard-admin/dashboard-admin.component';
import { ProjectParameterComponent } from './components/admin/project-parameter/project-parameter.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent, data: { animation: 'anim' } },
  {
    path: 'projects/:category',
    component: ProjectsComponent,
    data: { animation: 'projects/:category' },
  },
  {
    path: 'projects/:category/:id',
    component: ViewProjectComponent,
    data: { animation: 'projects/:category/:id' },
  },
  { path: 'login', component: LoginComponent },

  {
    path: 'dashboard-admin',
    component: DashboardAdminComponent,
    canActivate: [AngularFireAuthGuard],
  },
  {
    path: 'project-parameter',
    component: ProjectParameterComponent,
    canActivate: [AngularFireAuthGuard],
  },

  {
    path: 'create-project',
    component: CreateProjectComponent,
    canActivate: [AngularFireAuthGuard],
  },
  {
    path: 'view-all-project',
    component: ViewAllProjectComponent,
    canActivate: [AngularFireAuthGuard],
  },
  {
    path: 'edit-project/:id',
    component: EditProjectComponent,
    runGuardsAndResolvers: 'always',
    canActivate: [AngularFireAuthGuard],
  },
  {
    path: 'infos',
    component: InfosComponent,
    data: { animation: 'infos' },
  },
  {
    path: 'contacts',
    component: ContactsComponent,
    data: { animation: 'contacts' },
  },
  {
    path: 'mention-legal',
    component: MentionLegalComponent,
    data: { animation: 'mention-legal' },
  },

  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'home',
    data: { animation: 'home' },
  },
  { path: '**', component: FourOhFourComponent, data: { animation: 'fof' } },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}

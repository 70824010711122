import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { ProjectService } from 'src/app/services/project.service';
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons';
import { faInstagramSquare } from '@fortawesome/free-brands-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent implements OnInit {
  facebook = faFacebookSquare;
  insta = faInstagramSquare;
  linkedIn = faLinkedin;
  langFlag!: string;
  currentLang!: string;
  categoryList;
  constructor(
    private projectService: ProjectService,
    private authService: AuthService,
    private translate: TranslateService
  ) {
    this.categoryList = projectService.tagTypes;
  }

  ngOnInit(): void {
    this.currentLang = 'fr';
    this.langFlag = '../../../assets/img/fr.png';
    this.translate.use('fr');
  }
  auth = this.authService.auth;

  logOut() {
    this.authService.logout();
  }
  ToggleNavBar() {
    let element: HTMLElement = document.getElementsByClassName(
      'navbar-toggler'
    )[0] as HTMLElement;
    if (element.getAttribute('aria-expanded') == 'true') {
      element.click();
    }
  }
  onChangeLang() {
    if (this.currentLang === 'fr') {
      this.translate.use('en');
      this.langFlag = '../../../assets/img/en.png';
      this.currentLang = 'en';
    } else {
      this.translate.use('fr');
      this.langFlag = '../../../assets/img/fr.png';
      this.currentLang = 'fr';
    }
  }
}

<div class="container-fluid bg-dark marginMenu">
  <br />
  <div class="form-group col-4 mx-auto">
    <div class="input-group mb-3">
      <span class="input-group-text fw-bold bg-dark">Email</span>
      <input
        type="text"
        class="form-control bg-dark text-light"
        placeholder=""
        [(ngModel)]="email"
        required
      />
    </div>
    <div class="input-group mb-3">
      <span class="input-group-text fw-bold bg-dark">Password</span>
      <input
        type="password"
        class="form-control bg-dark text-light"
        placeholder=""
        [(ngModel)]="psw"
        required
      />
    </div>
    <button
      (click)="onLogin(email, psw)"
      type="button"
      class="btn btn-outline-primary d-grid gap-2 col-12 mx-auto"
    >
      Log In
    </button>
  </div>
  <br />
</div>
<br />

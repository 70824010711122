<video
  autoplay
  muted
  loop
  id="myVideo"
  class="d-block w-100"
  autoplay
  loop
  [muted]="'muted'"
>
  <source
    src="https://video.wixstatic.com/video/6fa99b_8d17952afcbd4cd5a7d7302e8103d52d/720p/mp4/file.mp4"
    type="video/mp4"
  />
  Your browser does not support HTML5 video.
</video>
<div *ngFor="let project of projects | async | orderBy: 'yearEnd':true">
  <h1
    class="fw-bold text-uppercase text-light"
    style="position: absolute"
    ngxParallaxScroll
    [parallaxSpeed]="0.05"
    [parallaxSmoothness]="1"
    [parallaxDirection]="'straight'"
    [parallaxTimingFunction]="'ease'"
    [parallaxThrottleTime]="0"
  >
    {{ project.clientName }} <br />
    <button
      type="button"
      class="btn btn-outline-light fs-1"
      (click)="navigateToProject(project.id)"
    >
      <span>{{ "viewProject" | translate | titlecase }}</span>
    </button>
  </h1>
  <img
    class="d-block w-100"
    *ngIf="project.coverImage.type === 'image'"
    src="{{ project.coverImage.url }}"
  />
</div>

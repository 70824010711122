import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import {
  contributorTypeId,
  ProjectService,
} from 'src/app/services/project.service';

@Component({
  selector: 'app-dashboard-admin',
  templateUrl: './dashboard-admin.component.html',
  styleUrls: ['./dashboard-admin.component.scss'],
})
export class DashboardAdminComponent implements OnInit {
  constructor(
    private projectService: ProjectService,
    private router: Router,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {}
  onNavigateToCreateProject() {
    this.router.navigate(['create-project']);
  }
  onNavigateToViewAllProject() {
    this.router.navigate(['view-all-project']);
  }
  onNavigateToProjectParameter() {
    this.router.navigate(['project-parameter']);
  }
}

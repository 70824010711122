import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  email = '';
  psw = '';
  constructor(private authService: AuthService) {}
  onLogin(email: string, psw: string) {
    this.authService.login(email, psw);
  }
  ngOnInit(): void {}
}

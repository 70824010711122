<div class="container-fluid bg-dark marginMenu">
  <br />
  <button
    (click)="onNavigateToCreateProject()"
    type="button"
    class="btn btn-outline-primary d-grid gap-2 col-3 mx-auto"
  >
    Créer un projet
  </button>
  <button
    (click)="onNavigateToViewAllProject()"
    type="button"
    class="btn btn-outline-primary d-grid gap-2 col-3 mx-auto"
  >
    Voir les projets</button
  ><button
    (click)="onNavigateToProjectParameter()"
    type="button"
    class="btn btn-outline-primary d-grid gap-2 col-3 mx-auto"
  >
    Configuration des projets
  </button>
  <br />
</div>

import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { finalize } from 'rxjs/operators';
import { UUID } from 'angular2-uuid';

export interface contributorType {
  en: string;
  fr: string;
}
export interface contributor {
  type: string;
  name: string;
}

export interface media {
  type: string;
  url: string;
  link: string;
}

export interface project {
  clientName: string;
  projectName: string;
  location: string;
  yearStart: string;
  yearEnd: string;
  comment: string;
  showOnSite: boolean;
  showOnHome: boolean;
  contributors: contributor[];
  tags: string[];
  images: media[];
  coverImage: media;
}
export interface projectId extends project {
  id: string;
}
export interface contributorTypeId extends contributorType {
  id: string;
}

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  private projectsCollection: AngularFirestoreCollection<project>;
  private contributorsCollection: AngularFirestoreCollection<contributorType>;

  projects: Observable<projectId[]>;
  contributorsType: Observable<contributorTypeId[]>;
  currentPercent: any;

  tagTypes = [
    'live',
    'exhibition',
    'event',
    'broadcast event',
    'comedy show',
    'architectural',
    '-',
    'lighting design',
    'scenography',
  ];
  years = [
    '2014',
    '2015',
    '2016',
    '2017',
    '2018',
    '2019',
    '2020',
    '2021',
    '2022',
    '2023',
    '2024',
    '2025',
    '2026',
    '2027',
    '2028',
    '2029',
    '2030',
    'ongoing...',
  ];

  initProject: project = {
    clientName: '',
    projectName: '',
    location: '',
    yearStart: '',
    yearEnd: '',
    comment: '',
    showOnSite: false,
    showOnHome: false,
    contributors: [
      { type: 'VculAoqDlGqaB6Zdi1f6', name: '4eleven' },
      { type: '9YUj0v8WBIm0Wowz1V2F', name: '4eleven' },
      { type: '3Jh1aYuqdYLCIeQ5KRjc', name: '4eleven' },
    ],
    tags: ['lighting design', 'scenography'],
    images: [],
    coverImage: { type: '', url: '', link: '' },
  };

  constructor(
    private firestore: AngularFirestore,
    private storage: AngularFireStorage
  ) {
    this.projectsCollection = firestore.collection<project>('projects');
    this.projects = this.projectsCollection.snapshotChanges().pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data() as project;
          const id = a.payload.doc.id;
          return { id, ...data };
        })
      )
    );
    this.contributorsCollection = firestore.collection<contributorType>(
      'contributors'
    );
    this.contributorsType = this.contributorsCollection.snapshotChanges().pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data() as contributorType;
          const id = a.payload.doc.id;
          return { id, ...data };
        })
      )
    );
  }

  getAllProjects() {
    return this.projects;
  }
  getProjectById(id: string) {
    let doc = this.projectsCollection.doc(id);
    return doc.snapshotChanges().pipe(
      map((actions) => {
        return actions.payload.data() as project;
      })
    );
  }
  updateProject(id: string, data: project) {
    this.projectsCollection.doc(id).update(data);
  }

  storeNewProjects(project: project) {
    this.projectsCollection.add(project);
  }

  makeCoverImage(media: media, project: project) {
    project.coverImage = media;
  }
  deleteFileFromURL(url: string) {
    return this.storage.refFromURL(url).delete();
  }

  uploadFile(file: File, project: project, id: string) {
    const uuidValue = UUID.UUID();

    const fileType = file.type.split('/', 2)[0];

    const filePath = 'projectImages/' + uuidValue;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);

    // push to currentProject.images[] when the download URL is available
    task
      .snapshotChanges()
      .pipe(
        finalize(() =>
          fileRef.getDownloadURL().subscribe((result) => {
            project.images.push({ type: fileType, url: result, link: '' });
            this.updateProject(id, project);
          })
        )
      )
      .subscribe();
    // observe percentage changes
    return task.percentageChanges();
  }

  getProjectsByCategory(category: string) {
    const filteredCollection = this.firestore.collection<project>(
      'projects',
      (ref) =>
        ref
          .where('showOnSite', '==', true)
          .where('tags', 'array-contains', category)
    );
    return filteredCollection.snapshotChanges().pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data() as project;
          const id = a.payload.doc.id;
          return { id, ...data } as projectId;
        })
      )
    );
  }
  getProjectsForHome() {
    const filteredCollection = this.firestore.collection<project>(
      'projects',
      (ref) => ref.where('showOnHome', '==', true)
    );
    return filteredCollection.snapshotChanges().pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data() as project;
          const id = a.payload.doc.id;
          return { id, ...data } as projectId;
        })
      )
    );
  }
  updateContributorType(id: string, data: contributorType) {
    this.contributorsCollection.doc(id).update(data);
  }
  deleteContributorType(id: string) {
    this.contributorsCollection.doc(id).delete();
    console.log(id);
  }
  storeNewContributorType(data: contributorType) {
    this.contributorsCollection.add(data);
  }
}

<div class="marginMenuProject">
  <h1
    class="blocktext fw-bold text-uppercase text-light"
    style="position: absolute; transform: translate(0%, -1000%)"
    ngxParallaxScroll
    [parallaxSpeed]="15"
    [parallaxSmoothness]="1"
    [parallaxDirection]="'reverse'"
    [parallaxTimingFunction]="'ease'"
    [parallaxThrottleTime]="0"
  >
    {{ project.clientName }}
  </h1>
  <h2
    class="blocktext fw-bold text-capitalize text-light"
    style="position: absolute; transform: translate(0%, -1000%)"
    ngxParallaxScroll
    [parallaxSpeed]="12"
    [parallaxSmoothness]="1"
    [parallaxDirection]="'reverse'"
    [parallaxTimingFunction]="'ease'"
    [parallaxThrottleTime]="0"
  >
    {{ project.projectName }}
  </h2>
  <h3
    class="blocktext fw-bold text-capitalize text-light"
    style="position: absolute; transform: translate(0%, -1000%)"
    ngxParallaxScroll
    [parallaxSpeed]="10"
    [parallaxSmoothness]="1"
    [parallaxDirection]="'reverse'"
    [parallaxTimingFunction]="'ease'"
    [parallaxThrottleTime]="0"
  >
    {{ project.location }} / {{ project.yearStart }}
    <span *ngIf="project.yearStart != project.yearEnd">
      - {{ project.yearEnd }}</span
    >
  </h3>

  <div *ngFor="let image of project.images">
    <video
      class="d-block w-100"
      *ngIf="image.type === 'video'"
      src="{{ image.url }}"
      (click)="onClickVideo(image.link)"
      autoplay
      loop
      [muted]="'muted'"
    ></video>
  </div>
  <div *ngFor="let image of project.images">
    <img
      class="d-block w-100"
      *ngIf="image.type === 'image'"
      src="{{ image.url }}"
    />
  </div>

  <div
    class="row row-cols-2 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 row-cols-xxl-6 justify-content-md-center"
  >
    <div
      class="col text-center my-4"
      *ngFor="let contributor of project.contributors"
    >
      <div *ngFor="let cont of contributorsType; index as i">
        <span
          class="fw-bold text-uppercase fs-md-4"
          *ngIf="cont.id === contributor.type"
        >
          {{ contributorsType[i].id | translate }}</span
        >
      </div>
      <br />
      <span class="fst-italic text-capitalize fs-md-4" style="color: #aaa">{{
        contributor.name
      }}</span>
    </div>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { Router } from '@angular/router';
import { projectId, ProjectService } from 'src/app/services/project.service';

@Component({
  selector: 'app-view-all-project',
  templateUrl: './view-all-project.component.html',
  styleUrls: ['./view-all-project.component.scss'],
})
export class ViewAllProjectComponent implements OnInit {
  projects: Observable<projectId[]>;

  constructor(private projectService: ProjectService, private router: Router) {
    this.projects = this.projectService.getAllProjects();
  }

  ngOnInit(): void {}

  editProject(id: string) {
    this.router.navigate(['edit-project', id]);
  }
}

<div class="container-fluid bg-dark marginMenu">
  <div class="row g-3">
    <div class="col-md-6">
      <div class="input-group mb-3">
        <span class="input-group-text fw-bold bg-dark">Nom du client</span>
        <input
          type="text"
          class="form-control bg-dark"
          placeholder="(ex : shakaponk)"
          [(ngModel)]="project.clientName"
          (change)="updateProject()"
        />
      </div>
    </div>
    <div class="col-md-6">
      <div class="input-group mb-3">
        <span class="input-group-text fw-bold bg-dark">Nom du projet</span>
        <input
          type="text"
          class="form-control bg-dark"
          placeholder="(ex : monkadelic tour)"
          [(ngModel)]="project.projectName"
          (change)="updateProject()"
        />
      </div>
    </div>
    <div class="col-md-4">
      <div class="input-group mb-3">
        <span class="input-group-text fw-bold bg-dark">Lieu</span>
        <input
          type="text"
          class="form-control bg-dark"
          placeholder="(ex : zénith et arena)"
          [(ngModel)]="project.location"
          (change)="updateProject()"
        />
      </div>
    </div>
    <div class="col-md-4">
      <div class="input-group mb-3">
        <span class="input-group-text fw-bold bg-dark">Année de début</span>
        <select
          class="form-select bg-dark"
          [(ngModel)]="project.yearStart"
          (change)="updateProject()"
        >
          <option selected disabled value="">Choose...</option>
          <option *ngFor="let year of years" [value]="year">
            {{ year }}
          </option>
        </select>
      </div>
    </div>
    <div class="col-md-4">
      <div class="input-group mb-3">
        <span class="input-group-text fw-bold bg-dark">Année de fin</span>
        <select
          class="form-select bg-dark"
          [(ngModel)]="project.yearEnd"
          (change)="updateProject()"
        >
          <option selected disabled value="">Choose...</option>
          <option *ngFor="let year of years" [value]="year">
            {{ year }}
          </option>
        </select>
      </div>
    </div>
    <div class="input-group">
      <span class="input-group-text fw-bold bg-dark">Description</span>
      <textarea
        class="form-control bg-dark"
        rows="5"
        placeholder="(ex : description du projet)"
        [(ngModel)]="project.comment"
        (change)="updateProject()"
      ></textarea>
    </div>

    <div class="form-check form-switch">
      <input
        class="form-check-input bg-secondary"
        type="checkbox"
        [(ngModel)]="project.showOnSite"
        (change)="updateProject()"
      />
      <label class="form-check-label fw-bold"> Afficher sur le site ? </label>
    </div>
    <div class="form-check form-switch">
      <input
        class="form-check-input bg-secondary"
        type="checkbox"
        [(ngModel)]="project.showOnHome"
        (change)="updateProject()"
      />
      <label class="form-check-label fw-bold">
        Afficher sur la première page ?
      </label>
    </div>
    <div class="col-md-6">
      <div *ngFor="let contributor of project.contributors; index as i">
        <div class="input-group">
          <select
            class="form-select bg-dark"
            [(ngModel)]="contributor.type"
            (change)="updateProject()"
          >
            <option selected disabled value="">Choose...</option>
            <option
              *ngFor="
                let type of contributorTypes | async | orderBy: 'fr':false
              "
              [value]="type.id"
            >
              {{ type.fr }}
            </option>
          </select>
          <input
            type="text"
            class="form-control bg-dark"
            placeholder="(ex : dushow,fimalac,...)"
            [(ngModel)]="contributor.name"
            (change)="updateProject()"
          />
          <button
            class="btn btn-outline-danger"
            type="button"
            (click)="onDeleteContrib(i)"
          >
            Delete
          </button>
        </div>
      </div>
      <button
        (click)="onNewContrib()"
        type="button"
        class="btn btn-outline-secondary d-grid gap-2 col-12 mx-auto"
      >
        Nouveau contributeur
      </button>
    </div>
    <div class="col-md-6">
      <div *ngFor="let tag of project.tags; index as i">
        <div class="input-group">
          <select
            class="form-select bg-dark"
            [(ngModel)]="project.tags[i]"
            (change)="updateProject()"
          >
            <option selected disabled value="">Choose...</option>
            <option *ngFor="let tag of tagTypes" [value]="tag">
              {{ tag }}
            </option>
          </select>

          <button
            class="btn btn-outline-danger"
            type="button"
            (click)="onDeleteTag(i)"
          >
            Delete
          </button>
        </div>
      </div>
      <button
        (click)="onNewTag()"
        type="button"
        class="btn btn-outline-secondary d-grid gap-2 col-12 mx-auto"
      >
        Nouveau #tag
      </button>
    </div>

    <div class="mb-3">
      <label for="images" class="form-label">
        <span class="fs-5 fw-bold bg-dark"> Importer des fichiers :</span>
        <span class="text-secondary fst-italic bg-dark">
          (ex : photo : .jpg, video : .mp4)</span
        ></label
      >
      <div class="input-group">
        <input
          class="form-control text-primary"
          type="file"
          (change)="getFilesToUpload($event)"
          multiple
        />
        <button
          (click)="uploadFiles(filesToUpload, project, id)"
          type="button"
          class="btn btn-outline-primary"
        >
          Upload Files
        </button>
      </div>

      <div class="progress" *ngIf="currentPercent">
        <div
          class="progress-bar"
          role="progressbar"
          [style.width]="currentPercent + '%'"
          aria-valuenow="25"
          aria-valuemin="0"
          aria-valuemax="100"
        >
          {{ currentPercent }}%
        </div>
      </div>
    </div>

    <h5>Cliquer sur l'image pour la mettre en couverture</h5>
    <div
      class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 row-cols-xxl-6 g-1"
    >
      <div class="col" *ngFor="let image of project.images; index as i">
        <div class="card border-secondary bg-dark text-white h-100">
          <div class="card-header">
            <small class="badge bg-secondary text-dark">
              {{ image.type }}</small
            >
            <small> &nbsp;</small>
            <small
              *ngIf="project.coverImage.url === image.url"
              class="badge bg-info text-dark"
              >Cover Image</small
            >
          </div>
          <video
            *ngIf="image.type === 'video'"
            src="{{ image.url }}"
            class="card-img-top"
            (click)="makeCoverImage(image, project)"
          ></video>
          <img
            *ngIf="image.type === 'image'"
            src="{{ image.url }}"
            class="card-img-top"
            (click)="makeCoverImage(image, project)"
          />

          <div class="card-footer">
            <div class="d-grid">
              <input
                *ngIf="image.type === 'video'"
                type="text"
                class="form-control bg-dark"
                placeholder="lien vers la vidéo"
                [(ngModel)]="image.link"
                (change)="updateProject()"
              />
              <button
                type="button"
                class="btn btn-outline-danger"
                (click)="onDeleteImage(i, image.url)"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<br />

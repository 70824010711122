<div class="container marginMenu">
  <h3 class="text-center">MENTIONS LÉGALES</h3>

  <h4>Identification de l'éditeur et de l'hébergeur du site</h4>
  <br />
  <p>
    Le site www.4eleven.fr est édité par 4eleven SAS, au capital de 12000 €,
    entreprise immatriculée au RCS de NANTES sous le numéro 819338344, dont le
    siège social est sis au 14 Rue des Bourdonnières, 44200 NANTES.
  </p>
  <p>N° de TVA intracommunautaire : FR27819338344.</p>
  <p>
    Directeur de la publication : Christophe Roirand, Président de 4eleven SAS,
    joignable au 0609713566 ou à l'adresse christophe@4eleven.fr.
  </p>
  <p>
    Le site est hébergé par OVH 2 Rue Kellermann BP 80157 59053 ROUBAIX CEDEX 1.
    Les informations concernant la collecte et le traitement des données
    personnelles (politique et déclaration) sont fournies dans la charte de
    données personnelles du site.
  </p>
  <p class="text-center">Tous droits réservés - 24 février 2021</p>
  <br /><br />
  <h3 class="text-center">
    POLITIQUE DE CONFIDENTIALITÉ ET D'UTILISATION DES DONNÉES PERSONNELLES
  </h3>
  <br /><br />
  <h4>Définition des termes utilisés dans la politique de confidentialité</h4>
  <br />
  <p>On désignera par la suite :</p>
  <ul>
    <li>
      « Donnée personnelle » : se définit comme « toute information relative à
      une personne physique identifiée ou qui peut être identifiée, directement
      ou indirectement, par référence à un numéro d'identification ou à un ou
      plusieurs éléments qui lui sont propres », conformément à la loi
      Informatique et libertés du 6 janvier 1978.
    </li>
    <li>
      « Service » : le service www.4eleven.fr et l'ensemble de ses contenus.
    </li>
    <li>
      « Editeur » ou « Nous » : 4eleven, personne morale ou physique responsable
      de l'édition et du contenu du Service.
    </li>
    <li>
      « Utilisateur » ou « Vous » : l'internaute visitant et utilisant le
      Service.
    </li>
  </ul>
  <h4>Article 1 - Introduction et rôle de la Politique de confidentialité</h4>
  <p>
    La présente charte vise à vous informer des engagements du Service eu égard
    au respect de votre vie privée et à la protection des Données personnelles
    vous concernant, collectées et traitées à l'occasion de votre utilisation du
    Service.
  </p>
  <p>
    Il est important que vous lisiez la présente politique de confidentialité
    afin que vous soyez conscient des raisons pour lesquelles nous utilisons vos
    données et comment nous le faisons.
  </p>
  <p>
    En vous inscrivant sur le Service, vous vous engagez à nous fournir des
    informations véritables vous concernant. La communication de fausses
    informations est contraire aux conditions générales figurant sur le Service.
  </p>
  <p>
    Veuillez noter que la présente Politique de confidentialité est susceptible
    d’être modifiée ou complétée à tout moment, notamment en vue de se conformer
    à toute évolution législative, réglementaire, jurisprudentielle ou
    technologique. La date de sa mise à jour sera clairement mentionnée, le cas
    échéant.
  </p>
  <p>
    Ces modifications vous engagent dès leur mise en ligne et nous vous invitons
    donc à consulter régulièrement la présente Politique de confidentialité afin
    de prendre connaissance des éventuelles modifications.
  </p>
  <p>
    Vous trouverez également la description de vos droits à la protection de la
    vie privée et la façon dont la loi vous protège.
  </p>
  <p>
    Si vous avez des questions concernant la présente politique de
    confidentialité ou si vous voulez exercer vos droits tels que décrit à
    l’article 10 de la présente Politique de confidentialité, veuillez nous
    contacter par e-mail à l’adresse : contact@4eleven.fr ou par courrier
    adressé à : 4eleven 14 Rue des Boutonnières 44200 NANTES.
  </p>
  <h4>Article 2 - Données collectées sur le Site</h4>
  <p>
    Les Données collectées et ultérieurement traitées par le Service sont celles
    que vous nous transmettez volontairement en remplissant les différents
    formulaires présents au sein du Service. Pour certaines opérations sur les
    contenus, vous pourrez être amenés à transmettre des Données vous concernant
    à des tiers partenaires au travers de leurs propres services, plus
    spécifiquement lors des paiements que vous pourrez effectuer. Nous ne
    disposerons pas des dites données, leur collecte et leur traitement étant
    régis par les conditions propres à ces intervenants. Nous vous invitons à
    consulter leurs conditions avant de communiquer vos Données dans ce cadre.
  </p>
  <p>
    Votre adresse IP (numéro d'identification attribué sur Internet à votre
    ordinateur) est collectée automatiquement. Vous êtes informés que le Service
    est susceptible de mettre en œuvre un procédé automatique de traçage
    (Cookie), auquel vous pouvez faire obstacle en modifiant les paramètres
    concernés de votre navigateur internet, comme expliqué dans les conditions
    générales du présent Service.
  </p>
  <p>
    D’une manière générale, il vous est possible de visiter le Service
    www.4eleven.fr sans communiquer aucune information personnelle vous
    concernant. Dans tous les cas, vous n’avez aucune obligation de transmettre
    ces informations. Néanmoins, en cas de refus, il se peut que vous ne
    puissiez pas bénéficier de certaines informations ou services.
  </p>
  <p>
    Nous recueillons, utilisons et partageons également des données agrégées
    telles que des données statistiques ou démographiques quel qu’en soit
    l’usage. Les données agrégées peuvent provenir de vos informations
    personnelles mais ne sont pas concernées comme telles par la loi car ces
    données ne révèlent pas directement votre identité. Par exemple, nous
    pouvons agréger vos données d’utilisation afin de calculer le pourcentage
    d’utilisateurs qui accèdent à une fonctionnalité spécifique du Service.
  </p>
  <p>
    Aux fins de fournir de meilleurs contenus et services, le Service
    www.4eleven.fr utilise le service d’analyse de Google Analytics. Google
    Analytics ne suit pas vos habitudes de navigation sur des services tiers.
    Les informations vous concernant auxquelles Google Analytics a accès ne
    contiennent aucune donnée personnelle vous concernant.
  </p>
  <p>Nous ne collectons pas de données dites « sensibles ».</p>
  <p>
    Les coordonnées des Utilisateurs du Service qui se seront inscrits sur
    celui-ci seront sauvegardées, dans le respect des dispositions de la loi
    informatique et liberté du 6 janvier 1978. Conformément à cette dernière,
    ils disposent d’un droit d’accès, de retrait, de modification ou de
    rectification des Données qu’ils ont fournies. Pour cela, il leur suffit
    d’en faire la demande à l’adresse électronique suivante :
    contact@4eleven.fr, ou par courrier : 4eleven 14 Rue des Boutonnières 44200
    NANTES.
  </p>
  <p>
    La collecte des Données personnelles des Utilisateurs par l'Editeur ne
    nécessite pas de déclaration auprès de l’autorité française de protection
    des Données personnelles (la Commission Nationale de l’Informatique et des
    Libertés – CNIL).
  </p>
  <h4>Article 3 - Identité du responsable du traitement</h4>
  <p>
    Le responsable du traitement est la société 4eleven, ayant son siège social
    à l'adresse suivante : 14 Rue des Bourdonnières, 44200 NANTES .
  </p>
  <h4>Article 4 - Finalité des Données collectées</h4>
  <p>
    Les Données identifiées comme étant obligatoires sur les formulaires du
    Service sont nécessaires afin de pouvoir bénéficier des fonctionnalités
    correspondantes du Service, et plus spécifiquement des opérations sur les
    contenus proposés au sein de celui-ci.
  </p>
  <p>
    Le Service est susceptible de collecter et traiter les Données de ses
    Utilisateurs :
  </p>
  <ul>
    <li>
      Aux fins de vous fournir les informations ou les services auxquels vous
      avez souscrit, notamment : Newsletter.
    </li>
    <li>
      Aux fins de recueillir des informations nous permettant d’améliorer notre
      Service, nos produits et fonctionnalités (notamment par l’usage des
      cookies).
    </li>
  </ul>
  <h4>Article 5 - Destinataires et utilisation des Données collectées</h4>
  <p>
    Les Données collectées par nos soins sont traitées pour les besoins
    d’exécution des opérations sur les contenus du Service.
  </p>
  <p>
    Vous êtes susceptible de recevoir des courriers électroniques (emails) de
    notre Service, notamment dans le cadre de newsletters que vous avez
    acceptées. Vous pouvez demander à ne plus recevoir ces courriers
    électroniques en nous contactant à l'adresse contact@4eleven.fr ou sur le
    lien prévu à cet effet dans chacun des courriers électroniques qui vous
    seront adressés.
  </p>
  <p>
    Seul 4eleven est destinataire de vos Informations personnelles. Celles-ci ne
    sont jamais transmises à un tiers, nonobstant les sous-traitants auxquels
    4eleven fait appel. Ni 4eleven ni ses sous-traitants ne procèdent à la
    commercialisation des données personnelles des visiteurs et utilisateurs de
    son Service.
  </p>
  <p>
    Vos données personnelles peuvent être partagées avec les parties indiquées
    ci-dessous aux fins définies dans la présente politique de confidentialité.
  </p>
  <p>
    Nous exigeons que tous les tiers garantissent la sécurité de vos données
    personnelles et les traitent conformément à la loi. Nous ne permettons pas à
    nos fournisseurs de services tiers d’utiliser vos données.
  </p>
  <h4>Article 6 - Fondements légaux régissant le traitement des données</h4>
  <p>
    Conformément au Règlement Général sur la Protection des Données (RGPD),
    4eleven ne traite des données à caractère personnel que dans les situations
    suivantes :
  </p>
  <ul>
    <li>avec votre consentement ;</li>
    <li>
      lorsqu'il existe une obligation contractuelle (un contrat entre 4eleven et
      vous) ;
    </li>
    <li>
      pour répondre à une obligation légale (en vertu de la législation UE ou
      nationale).
    </li>
  </ul>
  <h4>Article 7 - Sécurité des Données</h4>
  <p>
    Vous êtes informés que vos Données pourront être divulguées en application
    d'une loi, d'un règlement ou en vertu d'une décision d'une autorité
    réglementaire ou judiciaire compétente ou encore, si cela s'avère
    nécessaire, aux fins, pour l'Editeur, de préserver ses droits et intérêts.
  </p>
  <p>
    Nous avons mis en place des mesures de sécurité appropriées afin d’empêcher
    que vos données personnelles ne soient accidentellement perdues, utilisées,
    modifiées, dévoilées ou consultées sans autorisation. De plus, l’accès à vos
    données personnelles est soumis à une procédure de sécurité définie et
    documentée.
  </p>
  <h4>Article 8 - Durée de conservation des Données</h4>
  <p>
    Les Données sont stockées chez l'hébergeur du Service, dont les coordonnées
    figurent dans les mentions légales du Service, et sont conservées pour la
    durée strictement nécessaire à la réalisation des finalités visées ci-avant
    et ne saurait excéder 36 mois. Au-delà de cette durée, elles seront
    conservées à des fins exclusivement statistiques et ne donneront lieu à
    aucune exploitation, de quelque nature que ce soit.
  </p>
  <h4>
    Article 9 - Prestataires habilités et transfert vers un pays tiers de
    l’Union Européenne
  </h4>
  <p>
    4eleven vous informe qu’il a recours à des prestataires habilités pour
    faciliter le recueil et le traitement des données que vous nous avez
    communiquées. Ces prestataires sont situés dans l’Union Européenne
    exclusivement.
  </p>
  <p>
    4eleven s’est préalablement assuré de la mise en œuvre par ses prestataires
    de garanties adéquates et du respect de conditions strictes en matière de
    confidentialité, d’usage et de protection des données. Ces prestataires sont
    également soumis au Règlement Général sur la Protection des Données (RGPD).
  </p>
  <h4>Article 10 - Droits informatiques et libertés</h4>
  <p>
    Conformément à la législation sur la protection des données personnelles,
    vous avez les droits détaillés ci-après que vous pouvez exercer, comme
    indiqué à l’Article 1 de la Présente Politique de confidentialité en nous
    écrivant à l’adresse postale mentionnée en tête (4eleven 14 Rue des
    Boutonnières 44200 NANTES) ou en envoyant un courriel à contact@4eleven.fr :
  </p>
  <ul>
    <li>
      Le droit d’information : nous avons l’obligation de vous informer de la
      manière dont nous utilisons vos données personnelles (tel que décrit dans
      la présente politique de confidentialité).
    </li>
    <li>
      Le droit d’accès : c’est votre droit d’effectuer une demande d’accès aux
      données vous concernant afin de recevoir une copie des données à caractère
      personnel que nous détenons ; Toutefois, en raison de l’obligation de
      sécurité et de confidentialité dans le traitement des données à caractère
      personnel qui incombe à 4eleven, vous êtes informé que votre demande sera
      traitée sous réserve que vous rapportiez la preuve de votre identité,
      notamment par la production d’un scan ou d’une photocopie de votre titre
      d’identité valide.
    </li>
    <li>
      Le droit de rectification : le droit de nous demander de rectifier des
      données personnelles vous concernant qui seraient incomplètes ou
      inexactes. Au titre de ce droit, la législation vous autorise à demander
      la rectification, la mise à jour, le verrouillage ou encore l’effacement
      des données vous concernant qui peuvent être inexactes, erronées,
      incomplètes ou obsolètes.
    </li>
    <li>
      Le droit à l’effacement, aussi connu sous le nom de « droit à l’oubli » :
      dans certains cas, vous pouvez nous demander de supprimer les données
      personnelles que nous avons vous concernant (mis à part s’il existe une
      raison juridique impérieuse qui nous oblige à les conserver).
    </li>
    <li>
      Le droit à la limitation du traitement : vous avez le droit dans certains
      cas de nous demander de suspendre le traitement des données personnelles,
    </li>
    <li>
      Le droit à la portabilité des données : vous avez le droit de nous
      demander une copie de vos données personnelles dans un format courant (par
      exemple un fichier .csv).
    </li>
    <li>
      Le droit d’opposition : vous avez le droit de vous opposer au traitement
      de vos données personnelles (par exemple, en nous interdisant de traiter
      vos données à des fins de marketing direct).
    </li>
  </ul>
  <p>
    Cependant, l’exercice de ce droit n’est possible que dans l’une des deux
    situations suivantes : lorsque l’exercice de ce droit est fondé sur des
    motifs légitimes ou lorsque l’exercice de ce droit vise à faire obstacle à
    ce que les données recueillies soient utilisées à des fins de prospection
    commerciale.
  </p>
  <p>
    Contactez-nous si vous souhaitez exercer l’un des droits décrits ci-dessus
    en nous écrivant à 4eleven 14 Rue des Boutonnières 44200 NANTES ou par
    courriel à contact@4eleven.fr
  </p>
  <p>
    Vous n’aurez pas de frais à payer pour l’accès à vos données personnelles
    (ni pour l’exercice de tout autre droit). Cependant, nous pourrons vous
    facturer des frais raisonnables si votre demande est manifestement infondée,
    répétitive ou excessive. Dans ce cas, nous pouvons aussi refuser de répondre
    à votre demande.
  </p>
  <p>
    4eleven sera en droit, le cas échéant, de s’opposer aux demandes
    manifestement abusives de par leur caractère systématique, répétitif, ou
    leur nombre.
  </p>
  <p>
    Nous pouvons vous demander des informations spécifiques afin de confirmer
    votre identité et d’assurer votre droit d’accès à vos données personnelles
    (ou pour exercer tout autre droit). Il s’agit d’une mesure de sécurité pour
    garantir que ces données personnelles ne soient pas délivrées à une personne
    non autorisée à les recevoir. Nous pouvons aussi vous contacter pour obtenir
    plus d’informations concernant votre demande, afin de vous donner une
    réponse plus rapide.
  </p>
  <p>
    Nous essayons de répondre à toutes les demandes légitimes dans un délai d’un
    mois. Ce délai d’un mois peut être dépassé dans le cas où votre demande est
    particulièrement complexe ou si vous en avez fait plusieurs. Dans ce cas,
    nous vous préviendrons et vous tiendrons informé.
  </p>
  <h4>Article 11 - Plainte auprès de l’Autorité de protection des données</h4>
  <p>
    Si vous considérez que 4eleven ne respecte pas ses obligations au regard de
    vos Informations Personnelles, vous pouvez adresser une plainte ou une
    demande auprès de l’autorité compétente. En France, l’autorité compétente
    est la CNIL à laquelle vous pouvez adresser une demande par voie
    électronique à l'adresse suivante :
    https://www.cnil.fr/fr/plaintes/internet.
  </p>
  <h4>Article 12 - Politique relative aux cookies</h4>
  <p>
    Lors de votre première utilisation du Service www.4eleven.fr, vous êtes
    avertis par un bandeau que des informations relatives à votre navigation
    sont susceptibles d’être enregistrées dans des fichiers alphanumériques
    dénommés « cookies ». Notre politique d’utilisation des cookies vous permet
    de mieux comprendre les dispositions que nous mettons en œuvre en matière de
    navigation sur notre Service. Elle vous informe notamment sur l’ensemble des
    cookies présents sur notre Service, leur finalité et vous donne la marche à
    suivre pour les paramétrer.
  </p>
  <h5>a) Informations générales sur les cookies présents sur le site</h5>
  <p>
    4eleven, en tant qu’éditeur du présent Service, pourra procéder à
    l’implantation de cookies sur le disque dur de votre terminal (ordinateur,
    tablette, mobile etc.) afin de vous garantir une navigation fluide et
    optimale sur notre Service.
  </p>
  <p>
    Les « cookies » (ou témoins de connexion) sont des petits fichiers texte de
    taille limitée qui nous permettent de reconnaître votre ordinateur, votre
    tablette ou votre mobile aux fins de personnaliser les services que nous
    vous proposons.
  </p>
  <p>
    Les informations recueillies par le biais des cookies ne permettent en
    aucune manière de vous identifier nominativement. Elles sont utilisées
    exclusivement pour nos besoins propres afin d’améliorer l’interactivité et
    la performance de notre Service et de vous adresser des contenus adaptés à
    vos centres d’intérêts. Aucune de ces informations ne fait l’objet d’une
    communication auprès de tiers sauf lorsque 4eleven a obtenu au préalable
    votre consentement ou bien lorsque la divulgation de ces informations est
    requise par la loi, sur ordre d’un tribunal ou toute autorité administrative
    ou judiciaire habilitée à en connaître.
  </p>
  <p>
    Pour mieux vous éclairer sur les informations que les cookies identifient,
    vous trouverez un tableau listant les différents types de cookies
    susceptibles d’être utilisés sur le Service de 4eleven, leur nom, leur
    finalité ainsi que leur durée de conservation à l'adresse A VENIR.
  </p>
  <h5>b) Configuration de vos préférences sur les cookies</h5>
  <p>Vous pouvez accepter ou refuser le dépôt de cookies à tout moment.</p>
  <p>
    Lors de votre première utilisation du Service www.4eleven.fr, une bannière
    présentant brièvement des informations relatives au dépôt de cookies et de
    technologies similaires apparaît en bas de votre écran. Cette bannière vous
    avertit qu’en poursuivant votre navigation sur le Service de 4eleven (en
    chargeant une nouvelle page ou en cliquant sur divers éléments du Service
    par exemple), vous acceptez le dépôt de cookies sur votre terminal.
  </p>
  <p>
    Selon le type de cookie en cause, le recueil de votre consentement au dépôt
    et à la lecture de cookies sur votre terminal peut être impératif.
  </p>
  <h5>c) Les cookies exemptés de consentement</h5>
  <p>
    Conformément aux recommandations de la Commission Nationale de
    l’Informatique et des Libertés (CNIL), certains cookies sont dispensés du
    recueil préalable de votre consentement dans la mesure où ils sont
    strictement nécessaires au fonctionnement du Service ou ont pour finalité
    exclusive de permettre ou faciliter la communication par voie électronique.
    Il s’agit notamment des cookies d’identifiant de session,
    d’authentification, de session d’équilibrage de charge ainsi que des cookies
    de personnalisation de votre interface. Ces cookies sont intégralement
    soumis à la présente politique dans la mesure où ils sont émis et gérés par
    4eleven.
  </p>
  <h5>d) Les cookies nécessitant le recueil préalable de votre consentement</h5>
  <p>
    Cette exigence concerne les cookies émis par des tiers et qui sont qualifiés
    de « persistants » dans la mesure où ils demeurent dans votre terminal
    jusqu'à leur effacement ou leur date d’expiration.
  </p>
  <p>
    De tels cookies étant émis par des tiers, leur utilisation et leur dépôt
    sont soumis à leurs propres politiques de confidentialité dont vous
    trouverez un lien ci-dessous. Cette famille de cookie regroupe les cookies
    de mesure d’audience, les cookies publicitaires, auxquels 4eleven n’a pas
    recours, ainsi que les cookies de partage de réseaux sociaux (Facebook,
    YouTube, Twitter, LinkedIn, etc.). Les cookies de partage des réseaux
    sociaux sont émis et gérés par l’éditeur du réseau social concerné. Sous
    réserve de votre consentement, ces cookies vous permettent de partager
    facilement une partie du contenu publié sur le Service, notamment par
    l’intermédiaire d’un « bouton » applicatif de partage selon le réseau social
    concerné.
  </p>
  <p>
    Les cookies de mesure d’audience établissent des statistiques concernant la
    fréquentation et l’utilisation de divers éléments du Service (comme les
    contenus / pages que vous avez visité). Ces données participent à
    l’amélioration de l’ergonomie du Service. Sur le Service www.4eleven.fr, un
    outil de mesure d’audience (Google Analytics) est utilisé ; sa politique de
    confidentialité est disponible en français à l'adresse internet suivante :
    https://www.google.com/analytics/learn/privacy.html
  </p>
  <h5>e) Outils de paramétrage des cookies</h5>
  <p>
    La plupart des navigateurs Internet sont configurés par défaut de façon à ce
    que le dépôt de cookies soit autorisé. Votre navigateur vous offre
    l’opportunité de modifier ces paramètres standards de manière à ce que
    l’ensemble des cookies soit rejeté systématiquement ou bien à ce qu’une
    partie seulement des cookies soit acceptée ou refusée en fonction de leur
    émetteur.
  </p>
  <p>
    ATTENTION : Nous attirons votre attention sur le fait que le refus du dépôt
    de cookies sur votre terminal est néanmoins susceptible d’altérer votre
    expérience d’utilisateur ainsi que votre accès à certains services ou
    fonctionnalités du présent Service. Le cas échéant, 4eleven décline toute
    responsabilité concernant les conséquences liées à la dégradation de vos
    conditions de navigation qui interviennent en raison de votre choix de
    refuser, supprimer ou bloquer les cookies nécessaires au fonctionnement du
    Service. Ces conséquences ne sauraient constituer un dommage et vous ne
    pourrez prétendre à aucune indemnité de ce fait.
  </p>
  <p>
    Votre navigateur vous permet également de supprimer les cookies existants
    sur votre terminal ou encore de vous signaler lorsque de nouveaux cookies
    sont susceptibles d’être déposés sur votre terminal. Ces paramétrages n’ont
    pas d’incidence sur votre navigation mais vous font perdre tout le bénéfice
    apporté par le cookie.
  </p>
  <p>
    Veuillez ci-dessous prendre connaissance des multiples outils mis à votre
    disposition afin que vous puissiez paramétrer les cookies déposés sur votre
    terminal.
  </p>
  <h5>f) Paramétrage de votre navigateur internet</h5>
  <p>
    Chaque navigateur Internet propose ses propres paramètres de gestion des
    cookies. Pour savoir de quelle manière modifier vos préférences en matière
    de cookies, vous trouverez ci-dessous les liens vers l’aide nécessaire pour
    accéder au menu de votre navigateur prévu à cet effet.
  </p>
  <p>
    Google Chrome : https://support.google.com/chrome/answer/95647?hl=fr <br />
    Internet Explorer :
    https://support.microsoft.com/fr-fr/help/17442/windows-internet-explorer-delete-manage-cookies#ie=ie-11<br />
    Mozilla Firefox :
    https://support.mozilla.org/fr/kb/activer-desactiver-cookies<br />
    Opera : http://help.opera.com/Windows/10.20/fr/cookies.html<br />
    Safari https://support.apple.com/kb/PH21411?viewlocale=fr_FR&locale=fr_FR
  </p>
  <p>
    Pour de plus amples informations concernant les outils de maîtrise des
    cookies, vous pouvez consulter le site internet de la CNIL :
    https://www.cnil.fr/fr/cookies-les-outils-pour-les-maitriser.
  </p>
  <p>
    Pour toute interrogation ou demande complémentaire d’informations relative à
    la présente politique des cookies, merci de bien vouloir nous contacter.
  </p>
  <h5>g) Liste des cookies</h5>
  <p>
    La liste détaillée des cookies utilisés sur le Service www.4eleven.fr est
    disponible à l'adresse suivante : A VENIR.
  </p>
  <p class="text-center">Tous droits réservés - 24 février 2021</p>
</div>

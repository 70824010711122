<div class="container-fluid bg-dark marginMenu">
  <br />
  <div class="form-group col-4 mx-auto">
    <div class="mb-3">
      <label class="form-label">
        <span class="fs-5 fw-bold">Nom du client</span>
        <span class="text-secondary fst-italic"> (ex : shakaponk)</span></label
      >
      <input
        type="text"
        class="form-control bg-dark"
        [(ngModel)]="project.clientName"
      />
    </div>

    <div class="mb-3">
      <label class="form-label">
        <span class="fs-5 fw-bold">Nom du projet</span>
        <span class="text-secondary fst-italic">
          (ex : monkadelic tour)</span
        ></label
      >
      <input
        type="text"
        class="form-control bg-dark"
        [(ngModel)]="project.projectName"
      />
    </div>
    <button
      (click)="onCreateNewProject()"
      type="button"
      class="btn btn-outline-primary d-grid gap-2 col-12 mx-auto"
    >
      Créer le projet
    </button>
  </div>
  <br />
</div>
<br />

import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import {
  media,
  project,
  projectId,
  ProjectService,
  contributorTypeId,
  contributorType,
} from 'src/app/services/project.service';

@Component({
  selector: 'app-edit-project',
  templateUrl: './edit-project.component.html',
  styleUrls: ['./edit-project.component.scss'],
})
export class EditProjectComponent implements OnInit {
  project: project = this.projectService.initProject;
  id: any;
  years = this.projectService.years;
  contributorTypes!: Observable<contributorTypeId[]>;
  tagTypes = this.projectService.tagTypes;
  filesToUpload!: FileList;
  currentPercent: string = '';
  tempVideoLinkUrl: string = '';

  constructor(
    private projectService: ProjectService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.id = this.route.snapshot.paramMap.get('id');
    this.projectService.getProjectById(this.id).subscribe((x) => {
      this.project = x;
    });
    this.contributorTypes = projectService.contributorsType;
  }

  ngOnInit(): void {}
  onNewContrib() {
    this.project.contributors.push({ type: '', name: '' });
  }
  onDeleteContrib(index: number) {
    this.project.contributors.splice(index, 1);
    this.updateProject();
  }
  onDeleteImage(index: number, url: string) {
    this.projectService.deleteFileFromURL(url).subscribe(
      (value) => {
        console.log(value);
      },
      (error) => {
        console.log(error);
      },
      () => {
        console.log('Image supprimée');
        this.project.images.splice(index, 1);
        this.updateProject();
      }
    );
  }
  onNewTag() {
    this.project.tags.push('');
  }
  onDeleteTag(index: number) {
    this.project.tags.splice(index, 1);
    this.updateProject();
  }
  getFilesToUpload(e: any) {
    this.filesToUpload = e.target.files;
  }
  uploadFiles(files: FileList, project: project, id: string) {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      let percent = this.projectService.uploadFile(file, project, id);
      percent.subscribe((result) => {
        if (result) {
          this.currentPercent = result.toPrecision(3);
        }
        if (result === 100) {
          this.currentPercent = '';
        }
      });
    }
  }
  updateProject() {
    this.projectService.updateProject(this.id, this.project);
  }
  makeCoverImage(media: media, project: project) {
    this.projectService.makeCoverImage(media, project);
    this.updateProject();
  }
}

import { Component, OnInit } from '@angular/core';

import { faQuoteLeft, faQuoteRight } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-infos',
  templateUrl: './infos.component.html',
  styleUrls: ['./infos.component.scss'],
})
export class InfosComponent implements OnInit {
  faQuoteLeft = faQuoteLeft;
  faQuoteRight = faQuoteRight;
  constructor() {}

  ngOnInit(): void {}
}

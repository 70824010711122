import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import {
  contributorTypeId,
  project,
  ProjectService,
} from 'src/app/services/project.service';

@Component({
  selector: 'app-view-project',
  templateUrl: './view-project.component.html',
  styleUrls: ['./view-project.component.scss'],
})
export class ViewProjectComponent implements OnInit {
  project: project = this.projectService.initProject;
  contributorsType!: contributorTypeId[];
  id: any;

  constructor(
    private projectService: ProjectService,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService
  ) {
    let langFr = '{';
    let langEn = '{';

    this.projectService.contributorsType.subscribe(
      (x) => (
        (this.contributorsType = x),
        x.map(
          (x) => (langFr = langFr + '"' + x.id + '"' + ':' + '"' + x.fr + '",')
        ),
        x.map(
          (x) => (langEn = langEn + '"' + x.id + '"' + ':' + '"' + x.en + '",')
        ),
        this.translate.setTranslation(
          'fr',
          JSON.parse(langFr.slice(-langFr.length, -1) + '}'),
          true
        ),
        this.translate.setTranslation(
          'en',
          JSON.parse(langEn.slice(-langEn.length, -1) + '}'),
          true
        )
      )
    );
  }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    this.projectService
      .getProjectById(this.id)
      .subscribe((x) => (this.project = x));
  }
  onClickVideo(link: string) {
    window.open(link, '_blank');
  }
}
